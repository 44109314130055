function Logout() {
  window.localStorage.clear();
  window.location.href = '/intro';
  window.mixpanel.track('LogOut', {
    date: new Date().toISOString(),
  });
  return null;
}

export default Logout;
