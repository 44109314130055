const ROUTES = {
  INTRO: '/intro',
  ROOT: '/',
  LOGIN: '/login',
  HOME: '/home',
  REGISTER: '/register',
  PROFILE: '/profile',
  PROFILE_CREATE: '/profile/create',
  GREET: '/greet',
  USERS: '/users',
  LOGOUT: '/logout',
  TOS: '/tos',
  RECOVER_PASSWORD: '/recover-password',
  CHANGE_PASSWORD: '/reset/password',
  GROUPS: '/groups',
  GROUPS_CREATE: '/groups/create',
  COURSES: '/courses',
  BUDGET: '/budget',
  BUDGET_CREATE: '/budget/create',
  STORY: '/story',
  ADMIN: '/admin',
  TEST: '/test',
  END: '/end',
};

export default ROUTES;
